import {Transition} from '@headlessui/react';
import {NavLink} from '@remix-run/react';
import {Fragment, useState} from 'react';

const MOUSE_ENTER_TIMEOUT = 0;
const MOUSE_LEAVE_TIMEOUT = 300;

const MENU = [
  {className: 'flex lg:hidden', link: '/customers', name: 'Customers'},
  {className: 'flex lg:hidden', link: '/pricing', name: 'Pricing'},
  {className: 'flex xl:hidden', link: '/blog', name: 'Blog'},
  {className: 'flex xl:hidden', link: '/calculator', name: 'Calculator'},
];

export const MoreMenu = () => {
  let timeout: NodeJS.Timeout;

  const [isOpen, setIsOpen] = useState(false);

  const onHover = (action: string) => {
    if ((!isOpen && action === 'onMouseEnter') || (isOpen && action === 'onMouseLeave')) {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsOpen(!isOpen), action === 'onMouseEnter' ? MOUSE_ENTER_TIMEOUT : MOUSE_LEAVE_TIMEOUT);
    }
    // else: don't click! 😁
  };

  return (
    <div className="relative block xl:hidden">
      <div
        className=""
        onMouseEnter={() => onHover('onMouseEnter')}
        onMouseLeave={() => onHover('onMouseLeave')}
      >
        <div className={`_nav inline ${isOpen ? 'text-gray-500' : ''} relative cursor-pointer pb-4 hover:text-gray-400`}>More</div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
        >
          <div className="absolute left-1/2 z-50 mt-3 w-fit  -translate-x-1/2 px-4 sm:px-0">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
              <div className="relative space-y-1 bg-white p-4">
                {MENU.map((v) => (
                  <NavLink
                    className={({isActive}) =>
                      `_nav whitespace-nowrap items-center px-6 py-3 transition duration-150 ease-in-out rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50 ${v.className} ${isActive ? 'bg-gray-50 hover:bg-gray-100' : 'hover:bg-gray-50'}`
                    }
                    key={v.name}
                    onClick={() => setIsOpen(false)}
                    to={v.link}
                  >
                    {v.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
